
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import PriceCard from '@/views/motorcycle/common/PriceCard.vue';
import IPriceListData from '@/dataDescriptor/IPriceListData';

@Component<BaseLayout>({
    components: {PriceCard},
})
export default class BaseLayout extends Vue
{
    @Prop({required: true})
    protected heroImages!: string[];

    @Prop({required: true})
    protected title!: string;

    @Prop()
    protected subTitle?: string;

    @Prop()
    protected motorcycleAttributes?: object;

    @Prop({required: true})
    protected priceLists!: IPriceListData[];

    @Prop({required: true})
    protected availableForReservation!: boolean;

    //-----------------------------------------------------------------------------------------------------------------|

    @Ref('attribute-bar')
    protected infoBar!: HTMLDivElement;

    @Ref('attribute-bar-content')
    protected attributeBarContent!: HTMLDivElement;

    protected isAttributeBarOverflowing: boolean = false;

    public mounted()
    {
        this.handleWindowResize();
        window.addEventListener('resize', debounce(this.handleWindowResize, 300));
    }

    public handleWindowResize()
    {
        this.isAttributeBarOverflowing = (this.attributeBarContent.scrollWidth > this.infoBar.clientWidth)
            || (this.attributeBarContent.scrollWidth > this.infoBar.clientWidth);
    }
}
